import React from "react";
import { graphql, Link, navigate } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Helmet from "react-helmet";
import Embed from "react-runkit";
import cookie from "js-cookie";

import "typeface-poppins";

import "./styles/docs.scss";

import Footer from "./components/footer";
import {
	PreviewTab,
	PreviewBox,
	CodePreview,
} from "./components/docs/CodePreview";

import Logo from "./images/inlinesvgs/logo.svg";
import Logo2 from "./images/inlinesvgs/logo-docs.svg";
import IconInfo from "./images/inlinesvgs/icon-info.svg";
import IconWarning from "./images/inlinesvgs/icon-warning.svg";
import IconSuccess from "./images/inlinesvgs/icon-success.svg";
import HamburgerIcon from "./images/inlinesvgs/icon-hamburger.svg";
import MagnifierIcon from "./images/inlinesvgs/icon-magnifier.svg";
import IconCross from "./images/inlinesvgs/icon-xmark.svg";
import IconArrowRight from "./images/inlinesvgs/icons/arrow-right.svg";

import LayoutMain from "./components/layout";

const providerComponents = {
	pre: ({ children }) => {
		const { props } = children;
		const language = props.className && props.className.split("-")[1];

		return <CodePreview language={language}>{props.children}</CodePreview>;
	},
	PreviewTab,
	PreviewBox,
	HintSuccess,
	HintWarning,
	HintInfo,
	Runkit: ({ children }) => {
		const isBrowser = typeof window !== "undefined";

		const [isLoaded, setIsLoaded] = React.useState(() => {
			return !!(isBrowser && window.RunKit);
		});

		const [isEmbedLoaded, setIsEmbedLoaded] = React.useState(false);
		const code = children.trim();

		if (isLoaded && isBrowser && window.screen.width >= 800) {
			// const commentGuide = `\n\n// Click “▶ run” to try this code live.`;

			return (
				<div className="runkit-wrapper">
					{!isEmbedLoaded && (
						<CodePreview language="javascript">{code}</CodePreview>
					)}

					<Embed
						source={code}
						onLoad={() => {
							setIsEmbedLoaded(true);
						}}
					/>
				</div>
			);
		}

		return (
			<div>
				<Helmet
					onChangeClientState={(newState, addedTags) => {
						const { scriptTags } = addedTags;

						if (scriptTags && scriptTags[1]) {
							scriptTags[1].onload = () => setIsLoaded(true);
						}
					}}>
					<script src="https://embed.runkit.com" />
				</Helmet>

				<div className="runkit-wrapper">
					<CodePreview language="javascript">{code}</CodePreview>
				</div>
			</div>
		);
	},
	Separator90: () => {
		return <hr className="separator separator--size-90" />;
	},
};

export default function Template({ data, children }) {
	const { title, withOutline = false, layout } = data.mdx.frontmatter;

	return (
		<Layout title={title} layout={layout}>
			{withOutline && <Outline tableOfContents={data.mdx.tableOfContents} />}
			<MDXProvider components={providerComponents}>
				<MDXRenderer>{data.mdx.body}</MDXRenderer>
			</MDXProvider>
		</Layout>
	);
}

function HintSuccess({ children }) {
	return (
		<div className="highlight-block highlight-block--success">
			<IconSuccess className="icon" />
			<div>{children}</div>
		</div>
	);
}

function HintWarning({ children }) {
	return (
		<div className="highlight-block highlight-block--warning">
			<IconWarning className="icon" />
			<div>{children}</div>
		</div>
	);
}

function HintInfo({ children }) {
	return (
		<div className="highlight-block highlight-block--info">
			<IconInfo className="icon" />
			<div>{children}</div>
		</div>
	);
}

function LeftNavContent() {
	return (
		<>
			<LeftNavSection sectionName="API reference">
				<LeftNavItem url="/docs/api/authentication/">
					Authentication
				</LeftNavItem>
				<LeftNavItem url="/docs/api/email/">Sending emails</LeftNavItem>
				<LeftNavItem url="/docs/api/contacts/">Contact profiles</LeftNavItem>
				<LeftNavItem url="/docs/api/projects/">Linked projects</LeftNavItem>
				<LeftNavItem url="/docs/api/errors/">Handling errors</LeftNavItem>
			</LeftNavSection>

			<LeftNavSection sectionName="Email sending (guides)">
				<LeftNavItem url="/docs/email-sending-quickstart/">
					Quickstart
				</LeftNavItem>
				<LeftNavItem url="/docs/sending-identities/">
					Domain verification and DKIM
				</LeftNavItem>
				<LeftNavItem url="/docs/template-props/">
					Dynamic data with template props
				</LeftNavItem>
				<LeftNavItem url="/docs/markdown-emails/">
					Sending markdown emails
				</LeftNavItem>
				<LeftNavItem url="/docs/plain-text-emails/">
					Sending custom plain-text emails
				</LeftNavItem>
				<LeftNavItem url="/docs/custom-html-emails/">
					Sending custom HTML emails
				</LeftNavItem>
				<LeftNavItem url="/docs/email-attachments/">
					Sending files as email attachments
				</LeftNavItem>
				<LeftNavItem url="/docs/scheduled-email-delivery/">
					Scheduled email delivery
				</LeftNavItem>
				<LeftNavItem url="/docs/batch-email-sending/">
					Batch email sending
				</LeftNavItem>
				<LeftNavItem url="/docs/template-iteration-group/">
					Template iteration groups
				</LeftNavItem>
				<LeftNavItem url="/docs/data-charts/">Sending data charts</LeftNavItem>
				<LeftNavItem url="/docs/webhooks/">Webhooks</LeftNavItem>
			</LeftNavSection>

			<LeftNavSection sectionName="Contact profiles (guides)">
				<LeftNavItem url="/docs/contact-profiles-quickstart/">
					Quickstart
				</LeftNavItem>
			</LeftNavSection>
		</>
	);
}

function LeftNavItem(props) {
	return (
		<li>
			<Link to={props.url} activeClassName="is-active">
				{props.children}
			</Link>
		</li>
	);
}

function LeftNavSection(props) {
	return (
		<>
			<div className="label">{props.sectionName}</div>
			<ul>{props.children}</ul>
		</>
	);
}

function TopNavDesktop() {
	const isAlgoliaInitialized = React.useRef(false);
	const inputRef = React.useRef();

	const userJson = cookie.get("user");
	const user = userJson && JSON.parse(userJson);

	return (
		<header className="topNav">
			<div className="container">
				<div className="topNav-inner">
					<Link to="/">
						<Logo style={{ display: "block" }} width={130} />
					</Link>

					<div className="topNav-searchContainer">
						<input
							id="docs-search"
							className="input formControl formControl--search"
							placeholder="Search all docs"
							onFocus={() => {
								if (isAlgoliaInitialized.current) return;

								initAlgoliaSearch({ inputSelector: "#docs-search" });
								isAlgoliaInitialized.current = true;
								inputRef.current.focus();
							}}
							ref={inputRef}
						/>
					</div>

					{user && (
						<div>
							<span
								style={{
									fontSize: 14,
									color: "hsl(201, 12%, 33%)",
									marginRight: 20,
								}}>
								{user.name}
							</span>
							<a
								className="header-login button button--bright button--outline"
								href="https://client.sidemail.io">
								<span
									style={{
										marginRight: 10,
									}}>
									Dashboard
								</span>
								<IconArrowRight width={12} />
							</a>
						</div>
					)}

					{!user && (
						<div>
							<a
								className="header-login button button--bright button--outline"
								href="https://client.sidemail.io/login">
								Log in
							</a>

							<a
								className="header-signup"
								href="https://client.sidemail.io/register">
								Start 7-day trial
							</a>
						</div>
					)}
				</div>
			</div>
		</header>
	);
}

function MobileLeftMenuContent() {
	return (
		<>
			<div className="mobile-left-menu">
				<LeftNavContent />
			</div>
		</>
	);
}

const MobileMenu = function MobileMenu() {
	const [isLeftMenuOpen, setIsLeftMenuOpen] = React.useState(false);
	const [isMobileSearchOpen, setIsMobileSearchOpen] = React.useState(false);

	function handleLeftMenuOpen() {
		setIsLeftMenuOpen(true);
	}

	function handleLeftMenuClose() {
		setIsLeftMenuOpen(false);
	}

	function handleMobileSearchOpen() {
		setIsMobileSearchOpen(true);
	}

	function handleMobileSearchClose() {
		setIsMobileSearchOpen(false);
	}

	return (
		<div className="mobile-menu">
			<div className="container">
				<header className="topNav--mobile">
					<button
						className="button-hamburger-mobile"
						onClick={handleLeftMenuOpen}>
						<HamburgerIcon />
					</button>

					{isLeftMenuOpen === true && (
						<div className="mobile-menu-overlay" onClick={handleLeftMenuClose}>
							<MobileLeftMenuContent />
						</div>
					)}
					<Link to="/">
						<Logo2 className="logo" />
					</Link>

					<button
						className="button-search-mobile"
						style={{ visibility: "hidden" }}
						onClick={handleMobileSearchOpen}>
						<MagnifierIcon />
					</button>

					{isMobileSearchOpen === true && (
						<div className="mobile-search-box">
							<div>
								<input
									id="docs-search-mobile"
									className="search"
									placeholder="Search the Sidemail docs..."
								/>
							</div>
							<button
								className="button-close-search"
								onClick={handleMobileSearchClose}>
								<IconCross width={14} />
							</button>
						</div>
					)}
				</header>
			</div>
		</div>
	);
};

function renderOutlineItems(items) {
	return (
		<ul>
			{items.map(({ url, title, items }) => {
				return (
					<li key={url}>
						<a href={url}>{title}</a>

						{items && renderOutlineItems(items)}
					</li>
				);
			})}
		</ul>
	);
}

function Outline({ tableOfContents }) {
	return (
		<div className="outline">
			<div className="label">On this page:</div>
			{renderOutlineItems(tableOfContents.items)}
		</div>
	);
}

function initAlgoliaSearch({ inputSelector }) {
	if (!window.docsearch) return;

	window.docsearch({
		apiKey: "bd7032fbbdef766b1a9a1cb266dd8031",
		indexName: "sidemail",
		inputSelector: inputSelector,
		debug: true,
		handleSelected: (_input, _event, suggestion) => {
			// https://github.com/facebook/Docusaurus/blob/master/packages/docusaurus-theme-search-algolia/src/theme/SearchBar/index.js#L34
			const a = document.createElement("a");
			a.href = suggestion.url;

			const routePath = `${a.pathname}${a.hash}`;

			navigate(routePath);
		},
	});
}

function Layout({ children, title, layout = "docs" }) {
	if (layout === "legal") {
		return (
			<LayoutMain>
				<main>
					<Helmet>
						<title>{title}</title>
					</Helmet>

					<header className="aboveFold">
						<div className="container">
							<h1>{title}</h1>
						</div>
					</header>

					<section className="container">{children}</section>
				</main>
			</LayoutMain>
		);
	}

	return (
		<div id="docs">
			<Helmet title={title + " – Sidemail docs"}>
				<html lang="en" />

				<link
					rel="stylesheet"
					href="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css"
				/>
				<script src="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js" />
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				<link
					href="https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap"
					rel="stylesheet"
				/>
			</Helmet>

			<TopNavDesktop />
			<MobileMenu />

			<div className="container">
				<div className="layout">
					<aside className="leftNav">
						<nav className="leftNav-content">
							<LeftNavContent />
						</nav>
					</aside>

					<section className="content">{children}</section>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export const pageQuery = graphql`
	query DocsQuery($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			tableOfContents
			frontmatter {
				title
				path
				layout
			}
		}
	}
`;
